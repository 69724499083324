import React, { useEffect, lazy, Suspense, useState } from "react";
import LayoutLogged from "../../components/layout/logged";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Avatar, Badge, Button, Card, CardActions, CardContent, Checkbox, Chip, CircularProgress, LinearProgress, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, makeStyles, MenuItem, Paper, Select, useMediaQuery, withStyles } from "@material-ui/core";
import ScheduleIcon from '@material-ui/icons/Schedule';
import EventIcon from '@material-ui/icons/Event';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import Pusher from 'pusher-js';
import api from "../../services/api";
import WorkflowConfig from "../../../workflow-config";
import WorkflowCard from "../../components/dialog/workflow-card";
import { v4 as uuidv4 } from 'uuid';
import { isFuture } from 'date-fns';
import { daysTranslate, formatDateTime } from "../../services/date";
import { navigate } from "gatsby-link";
import lodash from 'lodash';
import { ifNotHaveAccessRedirect } from "../../services/auth";
import LeftMenu from "../../components/layout/left-menu";
import clsx from 'clsx';
import { invertColor } from "../../services/color";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const importComponent = component =>
  lazy(() => import(`../../${component}`));

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  margin: `0 0 ${grid}px 0`,
  minHeight: '100px',
  minWidth: '230px',
  background: isDragging ? "#FEFAE6" : "white",
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#eff9ef" : "#EBECF0",
  padding: grid,
  width: 250,
  minHeight: 130,
});

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: 'flex',
    overflow: 'visible',
    alignItems: 'flex-start',
    minHeight: 500,
  },
  flexBox: {
    minWidth: 300,
    padding: 0,
    width: 'auto',
  },
  columnTitle: {
    marginTop: 5,
    marginBottom: 15,
    color: '#565656',
    fontSize: '14px',
    fontStyle: 'inherit',
    lineHeight: '1.25',
    letterSpacing: '-0.006em',
    fontWeight: '600',
  },
  cardTemperature: {
    color: '#fff',
    marginBottom: '5px',
    fontSize: 10,
    height: '18px',
  },
  cardTemperatureHot: {
    background: 'rgb(230, 69, 69)',
  },
  cardTemperatureCold: {
    background: 'rgb(158 155 226)',
  },
  cardTemperatureWarm: {
    background: 'orange',
  },
  cardResume: {
    background: '#eee',
    marginRight: '5px',
    fontSize: 8
  },
  cardInfo: {
    padding: 0,
  },
  cardInfoItem: {
    padding: '0 5px',
  },
  cardInfoIcon: {
    minWidth: 30
  },
  cardInfoText: {
    fontSize: 14,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 280,
  },
}));

const StyledSelect = withStyles({
  root: {
    paddingTop: 1,
    paddingBottom: 1,
  },
})(Select);

// https://codesandbox.io/s/-w5szl?file=/src/index.js:1587-2346
export default function WorkflowBoard() {
  const classes = useStyles();

  const isSSR = typeof window === "undefined";

  const urlParams = new URLSearchParams(!isSSR ? window.location.search : undefined);
  const preFlightWorkflow = WorkflowConfig.workflows[urlParams.get('workflow') || WorkflowConfig.defaultWorkflow];

  const [cards, setCards] = useState(undefined);
  const [totalCards, setTotalCards] = useState(0);
  const [headerSum, setHeaderSum] = useState({});
  const [loading, setLoading] = useState(true);
  const [menuLeftOpened, setMenuLeftOpened] = useState(false);
  const [currentCard, setCurrentCard] = useState();
  const [currentWorkflow, setCurrentWorkflow] = useState(preFlightWorkflow);
  const [currentBoard, setCurrentBoard] = useState(preFlightWorkflow.boards[urlParams.get('board') || preFlightWorkflow.defaultBoard]);
  const [session] = useState(uuidv4());
  const [pusher, setPusher] = useState();
  const [dialogCreate, setDialogCreate] = useState([]);
  const [filters, setFilters] = useState({});
  const [filterTaskType, setFilterTaskType] = useState(false);

  const [optionsTaskType, setOptionsTaskType] = useState();

  const [openCreateClient, setOpenCreateClient] = React.useState(false);

  const matches = useMediaQuery('(min-width:600px)');

  useEffect(() => {
    (async () => {
      if (optionsTaskType || !filterTaskType) return;

      const response = await api.get('/option/task_type/values');
      setOptionsTaskType(response.data);
    })();
  }, [filterTaskType]);

  const handleFilterTask = (id) => {
    const newFilters = { ...filters };
    newFilters['type_id'] = id;

    setFilters(newFilters);
  };

  const loadCards = async () => {
    try {
      const response = await api.post(`/workflow/${currentWorkflow.id}/board`, {
        status: currentBoard.steps.map(current => current.id),
        ...filters
      });

      const data = response.data;
      let totalCards = 0;
      let headerSum = {};

      currentBoard.steps.forEach(current => {
        if (!data.hasOwnProperty(current.id)) {
          data[current.id] = [];
        } else {
          totalCards += data[current.id].length;

          if (current.headerSum) {
            if (data[current.id].length === 1) {
              headerSum[current.id] = doHeaderSum(current.headerSum, data[current.id][0]);
            } else {
              let total = 0;

              current.headerSum.forEach(field => {
                total += lodash.sumBy(data[current.id], field);
              });

              headerSum[current.id] = total;
            }
          }
        }
      });

      setTotalCards(totalCards);
      setHeaderSum(headerSum);
      setCards(data);
    } catch (error) {

    }
  };

  const doHeaderSum = (headerSum, card) => {
    const fields = typeof headerSum === 'string' ? [headerSum] : headerSum;
    let fieldTotal = 0;

    fields.forEach(field => {
      if (card[field]) {
        fieldTotal += parseFloat(card[field]);
      }
    });

    return fieldTotal;
  };

  const addCard = async (card) => {
    const cardsClone = Object.assign({}, cards);
    const step = cardsClone[card['status']];

    if (step) {
      step.unshift(card);
      setCards(cardsClone);
    }
  };

  const updateCard = async (card) => {
    if (!card) return;

    const cardsClone = Object.assign({}, cards);
    const step = cardsClone[card['status']];

    if (step) {
      const index = step.findIndex((obj => obj.id === card.id));

      if (index > -1) {
        step[index] = card;
        setCards(cardsClone);
      }
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);

      await loadCards();

      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkflow, currentBoard, filters]);

  useEffect(() => {
    if (!currentWorkflow?.id || cards === undefined) return;

    if (pusher?.pusher) {
      pusher.pusher.disconnect();
    }

    const pusherObj = new Pusher('eece863b555d82bf40a3', {
      cluster: 'us2',
    });

    setPusher({
      pusher: pusherObj,
      workflow: currentWorkflow.id,
    });

    const channel = pusherObj.subscribe(`workflow-${currentWorkflow.id}`);

    channel.bind('App\\Events\\WorkflowCardsCreated', async (data) => {
      if (!data?.card || session === data.session) return;

      loadCards();
    });

    channel.bind('App\\Events\\WorkflowCardsUpdated', async (data) => {
      if (!data?.cards?.length || session === data.session) return;

      loadCards();
    });

    // return () => {
    //   pusherObj.disconnect();
    // };
  }, [currentWorkflow, cards, filters]);

  useEffect(() => {
    (async () => {
      if (!currentWorkflow.forms.create) return;

      const Component = importComponent(`components/dialog/workflow-forms/${currentWorkflow.id}-create`);
      const isSSR = typeof window === "undefined";

      setDialogCreate(
        <>
          {!isSSR && (
            <Suspense fallback={''}>
              <Component
                session={session}
                open={openCreateClient}
                onClose={() => setOpenCreateClient(false)}
                onAfterSave={(card) => {
                  addCard(card);

                  setOpenCreateClient(false);
                }}
              />
            </Suspense>
          )}
        </>
      );
    })();
  }, [currentWorkflow, openCreateClient, cards]);

  const cardTemperatureColor = (temperature) => {
    if (temperature === 'hot') {
      return classes.cardTemperatureHot;
    } else if (temperature === 'warm') {
      return classes.cardTemperatureWarm;
    } else if (temperature === 'cold') {
      return classes.cardTemperatureCold;
    }
  };

  const cardTemperatureLabel = (temperature) => {
    if (temperature === 'hot') {
      return 'Quente';
    } else if (temperature === 'warm') {
      return 'Morno';
    } else if (temperature === 'cold') {
      return 'Frio';
    }
  };

  function onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const sInd = source.droppableId;
    const dInd = destination.droppableId;

    if (sInd === dInd && source.index === destination.index) {
      return;
    }

    const card = cards[sInd][source.index];
    if (card) {
      card.errors = undefined;
      card.moving = true;
    }

    if (sInd === dInd) {
      const items = reorder(cards[sInd], source.index, destination.index);
      persistMove(diffMovement(sInd, items), card);
      cards[sInd] = items;
    } else {
      const result = move(cards[sInd], cards[dInd], source, destination);

      persistMove([...diffMovement(sInd, result[sInd]), ...diffMovement(dInd, result[dInd])], card);

      cards[sInd] = result[sInd];
      cards[dInd] = result[dInd];
    }
  }

  const diffMovement = (status, items) => {
    const diff = [];

    items.forEach((item, index) => {
      if (item['order'] !== index || item['status'] !== status) {
        diff.push(item);
      }

      item['order'] = index;
      item['status'] = status;
    });

    return diff;
  };

  const persistMove = async (diff, cardSent) => {
    if (diff.length > 0) {
      try {
        await api.put(`/workflow/${currentWorkflow.id}/move?session=${session}`, diff);

        const cardsClone = Object.assign({}, cards);
        const cardFound = cardsClone[cardSent.status][cardSent.order];
        cardFound.moving = false;

        setCards(cardsClone);
      } catch (error) {
        if (!error?.response?.data || error.response.data.length === 0) {
          alert('Erro ao mover');
        }

        error?.response?.data?.forEach(current => {
          const source = { index: current.destination_order, droppableId: current.destination_status };
          const destination = { index: current.source_order, droppableId: current.source_status };

          const result = move(cards[current.destination_status], cards[current.source_status], source, destination);

          cards[current.destination_status] = result[current.destination_status];
          cards[current.source_status] = result[current.source_status];

          const card = cards[current.source_status].find(cardCurrent => cardCurrent.id === current.id);
          card.status = current.source_status;
          card.errors = current.errors;
          card.moving = false;

          setCards(Object.assign({}, cards));
        });
      }
    }
  };

  const handleChangeWorkflow = async (e) => {
    const preFlightWorkflow = WorkflowConfig.workflows[e.target.value];

    setCurrentWorkflow(preFlightWorkflow);
    setCurrentBoard(preFlightWorkflow.boards[preFlightWorkflow.defaultBoard]);
    navigate(`/workflow/board/?workflow=${e.target.value}`);
  };

  const handleChangeBoard = async (e) => {
    setCurrentBoard(currentWorkflow.boards[e.target.value]);
    navigate(`/workflow/board/?workflow=${currentWorkflow.id}&board=${e.target.value}`);
  };

  const handleOnFilter = (data) => {
    const newFilters = { ...filters };
    newFilters['responsible_user_id'] = data.users;

    setFilters(newFilters);
  };

  if (ifNotHaveAccessRedirect('process.view')) return <></>;

  return (
    <LayoutLogged title={`Processo: ${currentWorkflow.name}`}>
      <>
        <LeftMenu
          onFilter={handleOnFilter}
          onOpen={() => setMenuLeftOpened(true)}
          onClose={() => setMenuLeftOpened(false)}
        >
          {currentWorkflow.id === 'task' && (
            <List
              subheader={
                <ListSubheader component="div" id="nested-list-subheader-board">
                  Filtrar
            </ListSubheader>
              }
            >
              <>

                {!filterTaskType && (
                  <ListItem
                    onClick={() => setFilterTaskType(!filterTaskType)}
                    button
                  >
                    <ListItemText secondary="Tipo da tarefa" />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={(event) => {
                          setFilterTaskType(event.target.checked);
                          handleFilterTask(undefined);
                        }}
                        checked={filterTaskType}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
                {filterTaskType && (
                  <ListItem>
                    <ListItemText primary={(
                      <Select
                        fullWidth
                      >
                        <MenuItem
                          onClick={() => handleFilterTask(undefined)}
                        >Todos</MenuItem>
                        {optionsTaskType && optionsTaskType.map((option) => {
                          return <MenuItem
                            onClick={() => handleFilterTask(option.id)}
                            key={option.id}
                            value={option.id}>
                            {option.name}
                          </MenuItem>;
                        })}
                      </Select>
                    )} />
                  </ListItem>
                )}
              </>
            </List>
          )}
        </LeftMenu>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: menuLeftOpened,
          })}
          style={{ padding: 0, width: '99%' }}
        >
          {loading ? <LinearProgress /> : <div>
            <div style={{ marginLeft: 5, marginTop: 10 }}>
              {dialogCreate}

              {currentCard && (
                <WorkflowCard
                  card={currentCard}
                  open={currentCard ? true : false}
                  currentWorkflow={currentWorkflow}
                  onClose={() => setCurrentCard(false)}
                  onCardUpdate={(card) => {
                    setCurrentCard(card);
                    updateCard(card);
                  }}
                />
              )}

              {currentWorkflow.forms.create && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<PersonAddIcon />}
                  size="small"
                  style={{ marginRight: 10 }}
                  onClick={() => setOpenCreateClient(true)}
                  fullWidth={!matches}
                >
                  {currentWorkflow.labelNew}
                </Button>
              )}

              <StyledSelect
                labelId="workflows-label"
                id="workflows"
                value={currentWorkflow.id}
                variant="outlined"
                size="small"
                onChange={handleChangeWorkflow}
                style={{
                  paddingTop: 6,
                  paddingBottom: 6,
                  marginTop: !matches ? 10 : 0,
                }}
              >
                {
                  Object.keys(WorkflowConfig.workflows).map(w => {
                    return <MenuItem value={WorkflowConfig.workflows[w].id}>{WorkflowConfig.workflows[w].name}</MenuItem>;
                  })
                }
              </StyledSelect>

              <StyledSelect
                labelId="boards-label"
                id="boards"
                value={currentBoard.id}
                variant="outlined"
                size="small"
                onChange={handleChangeBoard}
                style={{
                  paddingTop: 6,
                  paddingBottom: 6,
                  marginLeft: 10
                }}
              >
                {
                  Object.keys(currentWorkflow.boards).map(board => {
                    let label = currentWorkflow.boards[board].name;

                    if (currentWorkflow.boards[board].id === currentBoard.id) {
                      label = `${totalCards} ${label}`;
                    }

                    return <MenuItem value={currentWorkflow.boards[board].id}>{label}</MenuItem>;
                  })
                }
              </StyledSelect>

              <div style={{ marginTop: 15, marginBottom: 15 }} />
              <List className={classes.flexContainer}>
                <DragDropContext onDragEnd={onDragEnd}>
                  {currentBoard.steps.map((current) => (
                    <ListItem className={classes.flexBox}>
                      <Droppable key={current.id} droppableId={current.id}>
                        {(provided, snapshot) => (
                          <Paper
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            {...provided.droppableProps}
                          >
                            <h4 className={classes.columnTitle}>
                              {current.label}
                        &nbsp;<span style={{ fontWeight: 400 }}>{cards[current.id]?.length}</span>
                              {headerSum[current.id] > 0 && <span style={{ fontWeight: 400 }}><br />{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(headerSum[current.id])}</span>}
                            </h4>
                            {cards[current.id] && cards[current.id].map((card, index) => {
                              return <Draggable
                                key={card.id}
                                draggableId={card.id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <Card
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                    onClick={() => {
                                      setCurrentCard(card);
                                    }}
                                  >
                                    <CardContent>
                                      {card.errors && (
                                        <div style={{ float: 'right' }}>
                                          <Badge
                                            badgeContent={card.errors.length}
                                            color="error"
                                          >
                                            <ReportProblemOutlinedIcon style={{ color: 'orange' }} />
                                          </Badge>
                                        </div>
                                      )}
                                      {card.moving && (
                                        <div style={{ float: 'right' }}>
                                          <CircularProgress size={20} color="secondary" />
                                        </div>
                                      )}

                                      {card.temperature && (
                                        <Chip
                                          label={cardTemperatureLabel(card.temperature)}
                                          className={[classes.cardTemperature, cardTemperatureColor(card.temperature)]}
                                          variant="default"
                                          size="small"
                                        />
                                      )}

                                      <List className={classes.cardInfo}>
                                        <ListItem className={classes.cardInfoItem}>
                                          <ListItemIcon className={classes.cardInfoIcon}>
                                            <AccountCircleIcon />
                                          </ListItemIcon>
                                          <ListItemText
                                            classes={{ primary: classes.cardInfoText }}
                                            primary={card.name}
                                          />
                                        </ListItem>

                                        {current.headerSum && (
                                          <ListItem className={classes.cardInfoItem}>
                                            <ListItemIcon className={classes.cardInfoIcon}>
                                              <MonetizationOnIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              classes={{ primary: classes.cardInfoText }}
                                              primary={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(doHeaderSum(current.headerSum, card))}
                                            />
                                          </ListItem>
                                        )}

                                        {card.phone && (
                                          <ListItem className={classes.cardInfoItem}>
                                            <ListItemIcon className={classes.cardInfoIcon}>
                                              <WhatsAppIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              classes={{ primary: classes.cardInfoText }}
                                              primary={card.phone}
                                            />
                                          </ListItem>
                                        )}
                                      </List>
                                    </CardContent>
                                    <CardActions disableSpacing>
                                      {card.responsible_user_name && (<Avatar style={{ width: 20, height: 20, fontSize: 12, marginRight: 4, background: card.responsible_user_color, color: card.responsible_user_color ? invertColor(card.responsible_user_color) : null }}>{card.responsible_user_name.charAt(0).toUpperCase()}</Avatar>)}
                                      <Chip label={daysTranslate(card.created_at)} variant="default" className={classes.cardResume} size="small" icon={<ScheduleIcon style={{ color: 'blue', width: 14, height: 14 }} />} />
                                      {daysTranslate(card.created_at) !== daysTranslate(card.status_updated_at) && <Chip label={daysTranslate(card.status_updated_at)} variant="default" className={classes.cardResume} size="small" icon={<ErrorOutlineIcon style={{ color: 'blue', width: 14, height: 14 }} />} />}
                                      {card.due_date && (
                                        <Chip label={formatDateTime(card.due_date)} variant="default" className={classes.cardResume} size="small" icon={<EventIcon style={{ color: isFuture(new Date(card.due_date)) ? '#59981A' : 'red', width: 14, height: 14 }} />} />
                                      )}
                                    </CardActions>
                                  </Card>
                                )}
                              </Draggable>;
                            })}
                            {provided.placeholder}
                          </Paper>
                        )}
                      </Droppable>
                    </ListItem>
                  ))}
                </DragDropContext>
              </List>
            </div>
          </div>}
        </main>
      </>
    </LayoutLogged>
  );
}