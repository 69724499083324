import React, { useEffect, useState } from "react";
import { Avatar, Checkbox, CircularProgress, CssBaseline, Divider, Drawer, IconButton, Input, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, ListSubheader, makeStyles, useTheme } from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import api from "../../services/api";
import { getCurrentUser } from "../../services/auth";
import { invertColor } from "../../services/color";
import { Alert, AlertTitle } from "@material-ui/lab";
import GeneralErrors from "../form/general-errors";

const drawerWidth = 275;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: 255,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    top: 65,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  menuHeader: {
    background: 'grey',
    height: 45,
    padding: 10,
    color: 'white',
    borderRadius: '50%',
    cursor: 'pointer',
    top: 70,
    left: 7,
    position: 'fixed',
    zIndex: 10,
  }
}));

export default function LeftMenu({
  children,
  onFilter,
  onOpen,
  onClose,
  withUnknownUsers,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [errors, setErrors] = useState();
  const [users, setUsers] = useState([]);
  const [usersSelected, setUsersSelected] = useState([]);
  const [withoutUserResponsible, setWithoutUserResponsible] = useState(false);

  const handleDrawerOpen = () => {
    onOpen && onOpen();
    setOpen(true);
  };

  const handleDrawerClose = () => {
    onClose && onClose();
    setOpen(false);
  };

  const handleUsersSelected = async (user, checked) => {
    const currentIndex = usersSelected?.indexOf(user.id);
    const newChecked = [...usersSelected || []];

    if (currentIndex === -1) {
      newChecked.push(user.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setUsersSelected(newChecked);
    setWithoutUserResponsible(undefined);

    onFilter({
      users: newChecked,
    })
  };

  useEffect(() => {
    if (withoutUserResponsible === true) {
      setUsersSelected(null);
    } else if (withoutUserResponsible === false) {
      setUsersSelected([]);
    }

  }, [withoutUserResponsible]);

  useEffect(() => {
    (async () => {
      setLoadingUsers(true);

      try {
        const response = await api.get(`/user?organization_id=${getCurrentUser().organization_id}`);
        setUsers(response.data.data);
      } catch (e) {
        setErrors(e?.response?.data || 'Erro desconhecido.');
      }

      setLoadingUsers(false);
    })();
  }, []);

  return (
    <>
      <CssBaseline />
      <div className={classes.menuHeader} onClick={handleDrawerOpen}>
        <a><ChevronRightIcon /></a>
      </div>
      {errors && (
        <>
          <Alert severity="error">
            <AlertTitle>Um erro aconteceu</AlertTitle>
            <GeneralErrors errors={errors} />
          </Alert>
          <br />
        </>
      )}
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />

        <List
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Usuários
            </ListSubheader>
          }
        >
          {loadingUsers ? <CircularProgress /> : (
            <>
              {users.map((currentUser, index) => (
                <ListItem
                  onClick={() => handleUsersSelected(currentUser, !usersSelected)}
                  button
                >
                  <ListItemAvatar>
                    <Avatar style={{ background: currentUser.color, color: currentUser.color ? invertColor(currentUser.color) : null }}>{currentUser.name.charAt(0)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText secondary={currentUser.name} />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      onChange={(event) => handleUsersSelected(currentUser, event.target.checked)}
                      checked={usersSelected?.includes(currentUser.id)}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </>
          )}

          {withUnknownUsers && (
            <ListItem
              onClick={() => setWithoutUserResponsible(!withoutUserResponsible)}
              button
            >
              <ListItemAvatar>
                <Avatar>?</Avatar>
              </ListItemAvatar>
              <ListItemText secondary="Sem responsável" />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  onChange={(event) => setWithoutUserResponsible(event.target.checked)}
                  checked={withoutUserResponsible}
                />
              </ListItemSecondaryAction>
            </ListItem>
          )}

          {children}
          <br /><br /><br /><br />
        </List>
      </Drawer>
    </>
  );
}